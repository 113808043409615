import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter } from 'vue-router'
import Home from '../views/page/Home.vue'
// import firebase from '@/firebase';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin-Login',
    component: () => import('../views/admin/Login.vue')
  },
  {
    path: '/admin/dashboard',
    name: 'Admin-Dashboard',
    component: () => import('../views/admin/Dashboard.vue'),
  },
  {
    path: '/client',
    name: 'Client-Login',
    component: () => import('../views/client/Login.vue')
  },
  {
    path: '/client/dashboard',
    name: 'Client-Dashboard',
    component: () => import('../views/client/Dashboard.vue'),
  },
  {
    path: '/login',
    name: 'User-Login',
    component: () => import('../views/user/Login.vue')
  },
  {
    path: '/games',
    name: 'User-Dashboard',
    component: () => import('../views/user/Dashboard.vue'),
  },
  {
    path: '/game-1a',
    name: 'Game-1a',
    component: () => import('../views/games/Game_1a.vue')
  },
  {
    path: '/game-1b',
    name: 'Game-1b',
    component: () => import('../views/games/Game_1b.vue')
  },
  {
    path: '/game-1c',
    name: 'Game-1c',
    component: () => import('../views/games/Game_1c.vue')
  },
  {
    path: '/game-2',
    name: 'Game-2',
    component: () => import('../views/games/Game_2.vue')
  },
  {
    path: '/game-3',
    name: 'Game-3',
    component: () => import('../views/games/Game_3.vue')
  },
  {
    path: '/game-4',
    name: 'Game-4',
    component: () => import('../views/games/Game_4.vue')
  },
  {
    path: '/game-5',
    name: 'Game-5',
    component: () => import('../views/games/Game_5.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.authRequired)) {
//     if (firebase.auth().currentUser) {
//       next();
//     } else {
//       next({ path: '/' });
//     }
//   } else {
//     next();
//   }
  
//   if (to.matched.some(record => record.meta.adminRequired)) {
//     if (firebase.auth().currentUser.uid === 'TvjlaEb9WxePQCm1wMInIxX5CtG2' || firebase.auth().currentUser.uid === 'ZvuWKFWgTUe7PAp0GtI3L1lUZ753') {
//       console.log('isAdmin: true');
//       next();
//     } else {
//       next({ path: '/' });
//     }
//   } else {
//     next();
//   }
// });

export default router
