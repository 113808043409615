import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCLanYjSZEuEKo_sKSZ9EA0BtsNoBvem4g",
  authDomain: "miasto-to-gra.firebaseapp.com",
  projectId: "miasto-to-gra",
  storageBucket: "miasto-to-gra.appspot.com",
  messagingSenderId: "408685433637",
  appId: "1:408685433637:web:8582f46fcbb4ded806c0cc"
};

export default firebase.initializeApp(firebaseConfig);