<template>
  <div id="home">
    <section id="about">
      <div class="padding flex" style="align-items:flex-start">
        <!-- <img src="/home/logo-white.png" alt="MiastoToGra.pl"> -->
        <div>
           <h1 style="margin:0;">MiastoToGra.pl</h1>
        <h2 style="margin-bottom:1rem;">Interaktywna Gra Terenowa</h2>
          <div
            style="
              width: 100px;
              height: 3px;
              background: #00c1cf;
              margin-bottom: 1rem;
            "
          ></div>

          <p>
            Podnieś głowę do góry i wyobraź sobie ogromną dłoń, która zbliża się
            do Ciebie, łapie za włosy i podnosi do góry. Pod Tobą znajduje się
            ogromna plansza z różnymi polami. Nagle, bez żadnego ostrzeżenia,
            dłoń puszcza Cię na jedno z pól i teraz będąc tajnym agentem
            wywiadu, stoisz przy zatłoczonej ulicy, rozglądając się za zdrajcą.
            Za chwilę będziesz musiał być bardziej kreatywny i rozwiązać
            tajemnicę ukrytego skarbu… Gratulacje! Właśnie wszedłeś do gry! Ty,
            chłopak przed Tobą i kobieta tam daleko. Wszyscy pragną odkryć
            nieznane.
          </p>

          <div class="width-600 btns-download-hero">
            <a
              href="/home/instrukcja_ios.jpg"
              target="_blank"
              style="margin: 0 1rem"
              ><button class="black-btn" style="margin: 1rem; min-width: 200px">
                Instrukcja instalacji iOS
              </button></a
            >
            <a
              href="/home/instrukcja_android.jpg"
              target="_blank"
              style="margin: 0 1rem"
              ><button class="black-btn" style="margin: 1rem; min-width: 200px">
                Instrukcja instalacji Android
              </button></a
            >
            <a
              href="/REGULAMIN_GRY_TERENOWEJ.pdf"
              target="_blank"
              style="margin: 0 1rem"
              ><button class="black-btn" style="margin: 1rem; min-width: 200px">
                Regulamin
              </button></a
            >
          </div>
        </div>
      </div>

      <div class="padding flex" style="background: #333;color:white;align-items:flex-start">
        <div>
          <h1 style="margin:0;">Pokoje Tajemnic</h1>
        <h2 style="margin-bottom:1rem;">Escape Room Karpacz</h2>
        <div
          style="
            width: 100px;
            height: 3px;
            background: #00c1cf;
            margin-bottom: 1rem;
          "
        ></div>

        <p>
          Pokoje Tajemnic w Karpaczu, to miejsce, w którym można wziąć udział w grze typu Escape the Room.
          Zabawa polega na ucieczce z pokoju pełnego zagadek. Uczestnicy znajdują ukryte przedmioty, by otrzymać wskazówki do rozwiązania zagadki, która da im wolność i pozwoli uciec z pokoju. Masz 60 minut, więc musisz być szybki! 
          Przyjdź ze znajomymi, rodziną, współpracownikami i baw się świetnie.
        </p>

        <div class="width-600 btns-download-hero">
        <a
              href="https://pokojetajemnic.pl/"
              target="_blank"
              ><button class="white-btn" style="margin: 2rem 0;min-width: 200px">
                Sprawdź
              </button></a
            >
        </div>
        </div>
      </div>
    </section>

    <section style="padding: 0">
      <div style="background: #000">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/FWjt91ohp60?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0"
          title="YouTube video player"
          frameborder="0"
        ></iframe>
      </div>
    </section>

    <section id="how-it-works">
      <h1>Jak to działa?</h1>
      <div
        style="width: 100px; height: 3px; background: #fff; margin: 1rem auto"
      ></div>
      <p class="width-600">
        MiastoToGra.pl to autorskie przygodowe i historyczne gry terenowe na
        urządzenia mobilne, których celem jest wspólna zabawa, rywalizacja
        między drużynami i przeżycie niezapomnianej przygody. Nasze gry są
        opracowane w taki sposób, aby gracz mógł poznać najciekawsze miejsca w
        okolicy, także te zapomniane – nie ujęte w przewodnikach. Przekazujemy w
        Państwa ręce interaktywną grę, angażującą różne zmysły pojedynczego
        gracza lub całej drużyny. Połączenie rozrywki ze zwiedzaniem i
        przyswajaniem wiedzy przy użyciu mapy, karty gry oraz urządzenia
        mobilnego to idealny przepis na udaną integrację rodziny, przyjaciół i
        każdej nowoczesnej firmy.
      </p>
      <p class="width-600 text-center">
        Wystarczy dowolny smartfon z dostępem do internetu! My zadbamy o resztę!
      </p>
      <div
        style="
          width: 100px;
          height: 3px;
          background: #fff;
          margin: 1.5rem auto 1rem auto;
        "
      ></div>
      <div class="pikto-grid">
        <div>
          <img src="/home/pikto/1.png" />
          <h4>1. WYBIERZ GRĘ</h4>
          <p>
            Wybierz dowolny scenariusz, a my zapewnimy niezapomnianą przygodę.
          </p>
        </div>
        <div>
          <img src="/home/pikto/2.png" />
          <h4>2. NAŁADUJ TELEFON</h4>
          <p>
            Korzystaj w pełni z aplikacji, posiadając naładowany telefon z
            dostępem do Internetu.
          </p>
        </div>
        <div>
          <img src="/home/pikto/3.png" />
          <h4>3. WYKUP DOSTĘP</h4>
          <p>
            Skontaktuj się z nami a otrzymasz mapę, kartę gry i dostęp do
            aplikacji. Poczuj radość korzystania w pełni z zabawy.
          </p>
        </div>
        <div>
          <img src="/home/pikto/4.png" />
          <h4>4. WYRUSZ W TRASĘ</h4>
          <p>
            W naszych grach stawiamy na różne trasy, które ukazują najciekawsze
            miejsca. Podążaj za wskazówkami, samodzielnie obieraj trasę i
            ustalaj strategię. Odkryj nieznane!
          </p>
        </div>
        <div>
          <img src="/home/pikto/5.png" />
          <h4>5. WYKONAJ ZADANIA</h4>
          <p>
            Nasze gry to wyjątkowe połączenie rywalizacji i współpracy dla osób
            w każdym wieku. Poznaj nowy wymiar integracji.
          </p>
        </div>
        <div>
          <img src="/home/pikto/6.png" />
          <h4>6. BAW SIĘ DOBRZE</h4>
          <p>Będzie dużo śmiechu i zabawy pozostającej na długo w pamięci.</p>
        </div>
      </div>

      <div class="width-600">
        <a
          href="/home/instrukcja_ios.jpg"
          target="_blank"
          style="margin: 0 1rem"
          ><button style="margin: 1rem; min-width: 200px">
            Instrukcja instalacji iOS
          </button></a
        >
        <a
          href="/home/instrukcja_android.jpg"
          target="_blank"
          style="margin: 0 1rem"
          ><button style="margin: 1rem; min-width: 200px">
            Instrukcja instalacji Android
          </button></a
        >
      </div>
    </section>

    <div class="parallax"></div>

    <section id="games">
      <h1>Gry</h1>
      <div
        style="
          width: 100px;
          height: 3px;
          background: #00c1cf;
          margin: 1rem auto 1.5rem auto;
        "
      ></div>

      <div class="games-list">
        <div class="game-card">
          <div
            class="game-bg"
            style="background-image: url('/game1/game1_card.jpg')"
          >
            <h2>Tajemnice Karkonoskiego Lasu</h2>
            <div
              style="
                width: 100px;
                height: 3px;
                background: #fff;
                margin: 1.2rem auto 1rem auto;
              "
            ></div>
            <h3>Zwierzęta</h3>
          </div>
          <p>
            Wybierz się w podróż poznając sekrety dzikiej przyrody. Ta gra
            przeniesie Cię w zdumiewający świat karkonoskich zwierząt Spaceruj,
            wykonuj zadania, zdobywaj punkty i odgaduj hasła. Jest to doskonała
            nauka i zabawa dla osób w każdym wieku. Nie musisz znać
            szczegółowych informacji dotyczących zwierząt a jedynie kierować się
            mapą, przeczuciem i własnymi podejrzeniami.
          </p>
        </div>

        <div class="game-card">
          <div
            class="game-bg"
            style="background-image: url('/game2/game2_card.jpg')"
          >
            <h2>Odkryj Karpacz</h2>
            <div
              style="
                width: 100px;
                height: 3px;
                background: #fff;
                margin: 1.2rem auto 1rem auto;
              "
            ></div>
            <h3>Dawni Mieszkańcy</h3>
          </div>
          <p>
            Wyrusz śladami dawnych mieszkańców Karpacza w miejsca, w których
            żyli, pracowali, lub takie, które są w inny sposób z nimi związane.
            Twoim zadaniem jest odczytanie przesłania, które po sobie
            pozostawili.
          </p>
        </div>

        <div class="game-card game-card-close">
          <!-- <div class="game-bg" style="background-image:url('/game3/game3_card.jpg')">
            <h2>Już wkrótce</h2>
            <div style="width:100px;height:3px;background:#fff;margin:1.2rem auto 1rem auto;"></div>
            <h3>Nowa Gra</h3>
          </div>
          <p></p> -->
          <h4>JUŻ WKRÓTCE NOWA GRA</h4>
        </div>
      </div>
    </section>

    <section id="price">
      <h1>Warianty cenowe naszych gier</h1>
      <div
        style="width: 100px; height: 3px; background: #fff; margin: 1rem auto"
      ></div>
      <p class="width-600 text-center">
        Oferujemy różne możliwości organizacji gier dopasowane do różnych
        budżetów i oczekiwań naszych klientów.
      </p>

      <div class="price-list">
        <div>
          <h3>STANDARD</h3>
          <ul>
            <li>
              Gra według jednego z przygotowanych scenariuszy ze wszystkimi
              elementami zapewniającymi emocjonującą i ciekawą rozrywkę,
            </li>
            <li>dostęp do aplikacji,</li>
            <li>profesjonalne materiały dla graczy,</li>
            <li>wsparcie techniczne,</li>
            <li>nagrody dla jednej najlepszej drużyny.</li>
          </ul>
          <div>
            <h3>CENA: 30zł za osobę</h3>
          </div>
        </div>
        <div>
          <h3>PREMIUM</h3>
          <ul>
            <li>
              Gra według naszego scenariusza jeszcze bardziej budująca klimat,
              emocje i zabawę,
            </li>
            <li>prowadzenie gry przez konferansjera,</li>
            <li>
              aktorzy w kostiumach teatralnych z opcjonalną charakteryzacją,
            </li>
            <li>profesjonalne materiały dla graczy,</li>
            <li>dostęp do aplikacji,</li>
            <li>wsparcie techniczne,</li>
            <li>
              indywidualne nagrody dla graczy w trzech najlepszych drużynach.
            </li>
          </ul>
          <div>
            <h4>
              Gra dostępna dla grup od 15 osób<br />
              Cena organizacji gry jest uzależniona od ilości osób i zespołów
              biorących udział w grze, a także innych indywidualnych ustaleń z
              klientem. W celu uzyskania szczegółowej wyceny dla konkretnej
              grupy prosimy o kontakt
            </h4>
          </div>
        </div>
        <div>
          <h3>NA ZAMÓWIENIE</h3>
          <ul>
            <li>Na zamówienie</li>
            <li>
              gra przygotowana od zera na podstawie oczekiwań klienta;
              zawierająca określone przez klienta motywy, wiedzę o firmie,
              dowolny sprzęt i technologię,
            </li>
            <li>prowadzenie gry przez konferansjera,</li>
            <li>infolinia dla graczy,</li>
            <li>profesjonalne materiały dla graczy,</li>
            <li>
              gra w aplikacji lub z fizycznymi materiałami według oczekiwań
              klienta,
            </li>
            <li>wsparcie techniczne,</li>
            <li>
              indywidualne nagrody dla graczy w trzech najlepszych drużynach.
            </li>
          </ul>
          <div>
            <h4>
              Gra dostępna dla grup od 15 osób
              <br />
              Cena organizacji gry jest uzależniona od ilości osób i zespołów
              biorących udział w grze, a także innych indywidualnych ustaleń z
              klientem. W celu uzyskania szczegółowej wyceny dla konkretnej
              grupy prosimy o kontakt
            </h4>
          </div>
        </div>
      </div>
    </section>

    <section id="collab">
      <h1>Współpraca</h1>
      <div
        style="
          width: 100px;
          height: 3px;
          background: #00c1cf;
          margin: 1rem auto;
        "
      ></div>
      <div class="width-600" style="margin: 1rem auto">
        <h2>Dla turystów</h2>
        <br />
        <p>
          <b
            >Zwiedzanie? Ciekawe miejsca w Karpaczu? Karpacz na weekend i
            dłużej? Dlaczego by nie, w końcu miasto pod Śnieżką potrafi
            zachwycić. Nawet kilka godzin w naszym mieście może stać się
            przygodą wartą zapamiętania.</b
          ><br /><br />
          Podnieś głowę do góry i wyobraź sobie ogromną dłoń, która zbliża się
          do Ciebie, łapie za włosy i podnosi do góry. Pod Tobą znajduje się
          ogromna plansza z różnymi polami. Nagle, bez żadnego ostrzeżenia, dłoń
          puszcza Cię na jedno z pól i teraz będąc tajnym agentem wywiadu,
          stoisz przy zatłoczonej ulicy, rozglądając się za zdrajcą. Za chwilę
          będziesz musiał być bardziej kreatywny i rozwiązać tajemnicę ukrytego
          skarbu… Gratulacje! Właśnie wszedłeś do gry! Ty, chłopak przed Tobą i
          kobieta tam daleko. Wszyscy pragną odkryć nieznane. Można inaczej?
          Oczywiście, że można! A my powiemy Ci jak. Nasz pomysł na zwiedzanie
          to po prostu pomysł idealny dla tych, którzy mają inne oczekiwania.
          Dla paczki przyjaciół, dla zgranej rodzinki, dla firmowych fachowców.
          Połącz zwiedzanie z dobrą zabawą.
        </p>
      </div>
    </section>

    <section id="collab-hotel" style="background: #f2f2f2; color: #000">
      <div>
        <h1>Współpraca</h1>
        <div
          style="
            width: 100px;
            height: 3px;
            background: #00c1cf;
            margin: 1rem auto;
          "
        ></div>
        <h2>Dla hoteli</h2>
        <br />
        <div class="grid-table">
          <div>
            <h4>GRA HOTELOWA</h4>
            <p>
              Całość gry odbywa się na terenie Państwa Hotelu, z wykorzystaniem
              jego infrastruktury. Zależnie od potrzeb fabuła gry może być
              kryminalna, przygodowa, historyczna lub luźno związana seria
              konkurencji. Zespoły graczy złożone z Państwa Gości rywalizują ze
              sobą o zwycięstwo i nagrodę gromadząc punkty.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>organizacja gry wg naszego scenariusza</li>
              <li>wyposażenie dla każdej drużyny</li>
              <li>dostęp do aplikacji</li>
              <li>hotline podczas gry</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych
                Moderatorów
              </li>
              <li>opieka techniczna podczas gry</li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
            </ul>
          </div>

          <div>
            <h4>GRA HOTELOWA<br />NA SPECJALNĄ OKAZJĘ</h4>
            <p>
              Gra przygotowana pod specjalne potrzeby Państwa Klienta np.
              promująca produkt lub wydarzenie albo misję i branżę firmy. Ten
              typ gry jest idealnym rozwiązaniem aby w ciekawy sposób
              zaprezentować nową kampanię, zrobić wprowadzenie do wielkich
              projektów, lub je z przytupem zakończyć. Scenariusz każdej gry
              pisany jest na zamówienie.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>koncepcja i organizacja wyposażenie dla każdej drużyny</li>
              <li>dostęp do aplikacji</li>
              <li>hotline podczas gry</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych
                Moderatorów
              </li>
              <li>opieka techniczna podczas gry</li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
            </ul>
          </div>

          <div>
            <h4>GRA TERENOWA<br />GDZIE TYLKO CHCESZ</h4>
            <p>
              Miejscem gry jest dowolne wybrane przez Państwa Klienta miejsce.
              Karpacz lub Jego miasto, siedziba i okolice Jego firmy, festyn,
              las, góry, jezioro oraz wiele innych.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>gra typu Outdoor</li>
              <li>koncepcja i organizacja wyposażenie dla każdej drużyny</li>
              <li>dostęp do aplikacji</li>
              <li>hotline podczas gry</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych
                Moderatorów
              </li>
              <li>opieka techniczna podczas gry</li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
            </ul>
          </div>

          <div>
            <h4>MOBILNY ESCAPE ROOM</h4>
            <p>
              Mobilny Escape Room to nietypowe rozwiązanie, które polega na
              przeniesieniu tradycyjnego Pokoju Tajemnic do miejsca, które nie
              jest wykorzystywane jako pokój zagadek. Oferujemy specjalnie
              przygotowany scenariusz, który realizujemy w każdych warunkach. W
              zależności od potrzeb Państwa Klientów mobilny Escape Room
              jesteśmy w stanie przygotować w Hotelu albo w plenerze. Pokój
              zagadek to atrakcja, która przetestuje umiejętności logicznego
              myślenia pod presją czasu oraz komunikację w grupie.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>aranżacja Mobilnego Escape Roomu</li>
              <li>przygotowanie i dostosowanie zagadek</li>
              <li>koncepcja i organizacja</li>
              <li>przystosowanie przestrzeni do gry</li>
              <li>wprowadzanie do gry dla każdej drużyny</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych Mistrzów
              </li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
              <li>serwis i opieka techniczna podczas gry</li>
              <li>doradztwo i konsultacja w wyborze gier</li>
            </ul>
          </div>
        </div>
        <h3>DLACZEGO MY?</h3>
        <br />
        <p class="text-center">JESTEŚMY Z PAŃSTWA GOŚĆMI PRZEZ CAŁY CZAS.</p>
        <p class="text-center">POSIADAMY SZEROKĄ GAMĘ USŁUG.</p>
        <p class="text-center">MAMY DOŚWIADCZENIE.</p>
        <div class="width-600">
          <p>
            Głównymi filarami naszej firmy są profesjonalizm i dobrze
            zorganizowana współpraca – te elementy, przed wszystkim, stanowią o
            prawidłowym funkcjonowaniu naszego przedsiębiorstwa. Zapewniamy
            indywidualne podejście do Klienta i szybko zmieniających się wymogów
            rynku. Działamy kompleksowo ponieważ znamy się na tym co robimy.
          </p>
        </div>
      </div>
    </section>

    <section id="collab-company">
      <div>
        <h1>Współpraca</h1>
        <div
          style="width: 100px; height: 3px; background: #fff; margin: 1rem auto"
        ></div>
        <h2>Dla firm</h2>
        <br />
        <div class="grid-table">
          <div>
            <h5>GRA TERENOWA<br />WYJŚCIE INTEGRACYJNE DLA AKTYWNYCH</h5>
            <p>
              Miejscem gry jest dowolne wybrane przez Państwa miejsce. Siedziba
              Państwa firmy i jej okolice, Karpacz lub inne miasto, festyn, las,
              góry, jezioro oraz wiele innych.
              <br /><br />
              Zależnie od potrzeb fabuła gry może być kryminalna, przygodowa,
              historyczna lub luźno związaną serią konkurencji. Zespoły graczy
              złożone z Państwa pracowników podczas gry rywalizują ze sobą o
              zwycięstwo i nagrodę gromadząc punkty.
              <br /><br />
              Gra może być przygotowana pod specjalne Państwa potrzeby np.
              promująca produkt lub wydarzenie albo misję i branżę firmy. Ten
              typ gry jest idealnym rozwiązaniem aby w ciekawy sposób
              zaprezentować nową kampanię, zrobić wprowadzenie do wielkich
              projektów, lub je z przytupem zakończyć.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>gra typu Outdoor</li>
              <li>koncepcja i organizacja</li>
              <li>wyposażenie dla każdej drużyny</li>
              <li>dostęp do aplikacji</li>
              <li>hotline podczas gry</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych
                Moderatorów
              </li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
            </ul>
          </div>

          <div>
            <h5>MOBILNY ESCAPE ROOM</h5>
            <p>
              Mobilny Escape Room to nietypowe rozwiązanie, które polega na
              przeniesieniu tradycyjnego Pokoju Tajemnic do miejsca, które nie
              jest wykorzystywane jako pokój zagadek. Oferujemy specjalnie
              przygotowany scenariusz, który realizujemy w każdych warunkach. W
              zależności od Państwa potrzeb mobilny Escape Room jesteśmy w
              stanie przygotować w biurze, hotelu albo w plenerze. Pokój zagadek
              to atrakcja, która przetestuje umiejętności logicznego myślenia
              pod presją czasu oraz komunikację w grupie.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>aranżacja Mobilnego Escape Roomu</li>
              <li>przygotowanie i dostosowanie zagadek</li>
              <li>koncepcja i organizacja</li>
              <li>przystosowanie przestrzeni do gry</li>
              <li>wprowadzanie do gry dla każdej drużyny</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych Mistrzów
                Gry
              </li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
              <li>serwis i opieka techniczna podczas gry</li>
              <li>doradztwo i konsultacja w wyborze gier</li>
            </ul>
          </div>

          <div>
            <h5>STACJONARNY ESCAPE ROOM</h5>
            <p>
              Pokoje Tajemnic to rozrywka, która zapewnia niezapomniane
              przeżycia i stanowi świetną formę spędzania czasu. Nasze
              scenariusze to inna historia osadzona w konkretnej kategorii,
              którą można dobrać do własnych preferencji.
              <br /><br />
              Pokoje Tajemnic świetnie sprawdzą się na integrację po pracy i
              podobnie jak w przypadku wyjścia ze znajomymi, pozwolą poznać
              siebie nawzajem.
              <br /><br />
              Nasz Pokoje Tajemnic wykorzystywane są również podczas rozmów
              kwalifikacyjnych. Gra sprawdza umiejętności interpersonalne
              potencjalnych przyszłych pracowników i pokazuje ich predyspozycje
              do zdolności przywódczych oraz to, czy potrafią pracować ze sobą w
              grupie.
            </p>
            <b>USŁUGI W PAKIECIE:</b>
            <ul>
              <li>60-minutowa gra w pokojach zagadek</li>
              <li>wprowadzanie do gry</li>
              <li>
                moderacja podczas gry obsługiwana przez doświadczonych Mistrzów
                Gry
              </li>
              <li>punktacja</li>
              <li>wyłonienie zwycięzców i wręczenie nagrody</li>
              <li>pamiątkowe zdjęcia</li>
            </ul>
          </div>
        </div>
        <h3>DLACZEGO MY?</h3>
        <br />
        <p class="text-center">JESTEŚMY Z PAŃSTWA GOŚĆMI PRZEZ CAŁY CZAS.</p>
        <p class="text-center">POSIADAMY SZEROKĄ GAMĘ USŁUG.</p>
        <p class="text-center">MAMY DOŚWIADCZENIE.</p>
        <div class="width-600">
          <p>
            Głównymi filarami naszej firmy są profesjonalizm i dobrze
            zorganizowana współpraca – te elementy, przed wszystkim, stanowią o
            prawidłowym funkcjonowaniu naszego przedsiębiorstwa. Zapewniamy
            indywidualne podejście do Klienta i szybko zmieniających się wymogów
            rynku. Działamy kompleksowo ponieważ znamy się na tym co robimy.
          </p>
        </div>
      </div>
    </section>

    <section id="partners">
      <div>
        <h1>Partnerzy</h1>
        <div
          style="width: 100px; height: 3px; background: #fff; margin: 1rem auto"
        ></div>
        <br />
        <div class="width-600">
          <div class="partners-grid">
            <a href="https://pokojetajemnic.pl" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/04/pokojetajemnic_logo.png"
                alt="escape room karpacz"
            /></a>
            <a href="https://miastotogra.pl" target="_blank"
              ><img
                src="https://miastotogra.pl/home/logo-white.png"
                alt="miasto to gra"
            /></a>
            <a href="https://karpacz-express.pl/" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/05/karpaczexpress.png"
                alt="karpacz express"
            /></a>
            <a href="https://starlab.dev" target="_blank"
              ><img
                src="https://starlab.dev/wp-content/uploads/2019/12/starlab-logo-full-white.png"
                alt="starlab tworzenie stron www"
            /></a>
            <a href="https://noclegi.net.pl/karpacz" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/07/noclegi_net_pl.png"
                alt="noclegi.net.pl"
            /></a>
            <a href="https://karpacz.net/noclegi" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/07/karpacz_net.png"
                alt="noclegi karpacz netfactory"
            /></a>
            <a href="https://szklarska-poreba.pl/noclegi" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/07/szklarska-poreba_pl.png"
                alt="noclegi szklarska poręba netfactory"
            /></a>
            <a href="https://swieradow-zdroj.pl/noclegi" target="_blank"
              ><img
                src="https://pokojetajemnic.pl/wp-content/uploads/2021/07/swieradow-zdroj_com.png"
                alt="noclegi świeradów zdrój netfactory"
            /></a>
          </div>
        </div>
      </div>
    </section>

    <section id="contact">
      <h1>Kontakt</h1>
      <div
        style="width: 100px; height: 3px; background: #fff; margin: 1rem auto"
      ></div>
      <div class="grid-table">
        <a href="#contact">
          <div><i class="fas fa-home"></i></div>
          <p style="text-align: center">
            ul. Konstytucji 3-go Maja 57<br />58-540 Karpacz
          </p>
        </a>
        <a href="tel:+48600706950">
          <div><i class="fas fa-phone"></i></div>
          <p>+48 600 706 950</p>
        </a>
        <a href="mailto:kontakt@miastotogra.pl">
          <div><i class="fas fa-envelope"></i></div>
          <p>kontakt@miastotogra.pl</p>
        </a>
        <a href="https://www.facebook.com/MiastoToGra" target="_blank">
          <div><i class="fab fa-facebook-f"></i></div>
          <p>facebook</p>
        </a>
      </div>
    </section>
    <div style="padding: 1rem; font-size: 12px">
      <a href="https://starlab.dev" target="_blank" style="color: white">
        <img
          height="30"
          src="https://starlab.dev/wp-content/uploads/2019/12/starlab-logo-full-white.png"
          alt="starlab tworzenie stron internetowych i aplikacji mobilnych"
        />
        <br />Tworzenie rozwiązań IT
      </a>
    </div>
    <header class="header">
      <a href="#"
        ><img class="logo" src="/home/logo-white.png" alt="MiastoToGra.pl"
      /></a>
      <div style="display: flex; cursor: pointer">
        <nav style="margin-right: 1rem">
          <ul>
            <li><a href="#home">HOME</a></li>
            <li><a href="#how-it-works">JAK TO DZIAŁA?</a></li>
            <li><a href="#games">GRY</a></li>
            <li><a href="#price">CENNIK</a></li>
            <li><a href="#collab">WSPÓŁPRACA</a></li>
            <li><a href="#contact">KONTAKT</a></li>
          </ul>
        </nav>
        <a href="/login" class="btn login-btn"><button>Wejdź do gry</button></a>
        <i class="fas fa-bars nav-mobile-btn" @click="mobileMenu = true"></i>
      </div>
    </header>
    <div class="nav-mobile" v-if="mobileMenu">
      <i
        class="fas fa-times nav-mobile-close"
        style="cursor: pointer"
        @click="mobileMenu = false"
      ></i>
      <img class="logo" src="/home/logo-white.png" alt="MiastoToGra.pl" />
      <ul>
        <li><a href="#home" @click="mobileMenu = false">HOME</a></li>
        <li>
          <a href="#how-it-works" @click="mobileMenu = false">JAK TO DZIAŁA?</a>
        </li>
        <li><a href="#games" @click="mobileMenu = false">GRY</a></li>
        <li><a href="#price" @click="mobileMenu = false">CENNIK</a></li>
        <li><a href="#collab" @click="mobileMenu = false">WSPÓŁPRACA</a></li>
        <li><a href="#contact" @click="mobileMenu = false">KONTAKT</a></li>
      </ul>
      <a href="/login" class="btn"><button>Wejdź do gry</button></a>
    </div>
    <div class="popup" v-if="popupVisible">
      <div class="popup-header">
        <div></div>
        <i
          class="fas fa-times nav-mobile-close"
          style="cursor: pointer"
          @click="popupVisible = false"
        ></i>
      </div>
      <img src="/home/plakatmisie.jpg" />
      <div></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import firebase from "@/firebase";

export default {
  name: "Home",
  data() {
    return {
      users: [],
      mobileMenu: false,
      popupVisible: false,
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      const db = firebase.firestore();
      const ref = db.collection("users");
      const snapshot = await ref.get();
      snapshot.forEach((doc) => {
        this.users.push(Object.assign({ id: doc.id }, doc.data()));
      });
      console.log(this.users);
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.9), #000);
  padding: 1rem;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}
.popup img {
  max-width: 95vw;
  max-height: 95vh;
}
#home {
  font-family: "Oswald", sans-serif;
  background: #000;
  color: #fff;
}
h1 {
  font-size: 3rem;
  font-weight: 100;
  margin-bottom: 1rem;
}
h2 {
  font-size: 2rem;
  font-weight: 100;
}
h3 {
  font-size: 1.8rem;
  font-weight: 100;
}
p {
  line-height: 1.7rem;
  text-align: justify;
  /* color: #fff; */
}
a {
  text-decoration: none;
}
/* a.btn {
  text-decoration-style: wavy !important;
  text-decoration-color: transparent;
  transition: 0.3s all;
}
a.btn:hover {
  text-decoration-style: wavy !important;
  text-decoration-color: white;
} */
button {
  all: unset;
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 32px;
  position: relative;
  text-shadow: none;
  text-align: center;
}
button::after {
  position: absolute;
  top: -8px;
  right: 4px;
  bottom: 4px;
  left: -8px;
  content: "";
  border: 2px solid #fff;
  transition: 0.3s all;
}
button:hover::after {
  top: 4px;
  bottom: -8px;
  right: -8px;
  left: 4px;
}

.black-btn {
  all: unset;
  border: 2px solid #000;
  color: #000;
  text-transform: uppercase;
  padding: 8px 32px;
  position: relative;
  text-shadow: none;
  text-align: center;
}
.black-btn::after {
  position: absolute;
  top: -8px;
  right: 4px;
  bottom: 4px;
  left: -8px;
  content: "";
  border: 2px solid #000;
  transition: 0.3s all;
}
.black-btn:hover::after {
  top: 4px;
  bottom: -8px;
  right: -8px;
  left: 4px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: #0080bb;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  box-shadow: 0 0px 10px 0 #000;
  padding: 0.5rem 1rem;
}
header nav ul {
  list-style-type: none;
  display: flex;
}
header nav ul li {
  margin: 0 8px;
  padding: 4px;
}
header nav ul li a {
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.3s all;
  border-bottom: 3px solid transparent;
}
header nav ul li a:hover {
  border-bottom: 3px solid;
}

.logo {
  height: 60px;
}

section {
  padding: 6rem 1rem;
}

section#about {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(280px, 1fr));
  padding: unset;
  text-align: left;
  min-height: 600px;
  background: #fff;
  color: #000;
  margin-top: 75px;
}

section#how-it-works {
  background: linear-gradient(45deg, #000, #242424);
}

section#games {
  background: #fff;
  color: #000;
}

section#games .games-list {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

section#price {
  background: linear-gradient(45deg, #000, #242424);
}

section#collab {
  color: #000;
  background: #fff;
}

section#contact {
  background: linear-gradient(45deg, #0080bb, #00c1cf);
}

.pikto-grid {
  display: grid;
  justify-content: center;
  gap: 2rem;
  grid-template: 1fr 1fr / repeat(3, minmax(280px, 400px));
}
.pikto-grid > div {
  margin: 1rem 0;
  background: #fff;
  border-radius: 1rem;
  color: #000;
  padding: 2rem;
  box-shadow: 0 10px 20px 0 #000;
}
.pikto-grid > div > h4 {
  margin-bottom: 1rem;
}
.pikto-grid > div > p {
  text-align: center;
}

.game-card {
  /* padding: 2rem; */
  border-radius: 1rem;
  box-shadow: 0 30px 40px -20px #00c1cf;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  color: #fff;
  margin-bottom: 1rem;
  position: relative;
}
.game-card-close {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}
.game-card-close::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url(/img/curtain.png);
  background-size: 100% 100%;
  border-radius: 1rem;
}
.game-card p {
  padding: 2rem;
}
.game-bg {
  background-size: cover;
  background-position: bottom center;
  padding: 2rem 0;
  border-radius: 1rem 1rem 0 0;
  text-shadow: 0 0 10px #000;
}
.price-list {
  display: grid;
  justify-content: center;
  grid-template: 1fr / repeat(auto-fit, minmax(280px, 400px));
  gap: 2rem;
  margin: 2rem 0;
}

.price-list ul {
  list-style-type: none;
  margin: 2rem;
  /* list-style-position: inside; */
}
.price-list ul li {
  position: relative;
  margin-bottom: 1rem;
}
.price-list ul li::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 2px;
  background: #00c1cf;
  left: calc(50% - 20px);
  bottom: -0.6rem;
}

.price-list > div {
  background: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 20px 0 #000;
}
.price-list h3 {
  margin-bottom: 1rem;
}

.price-list > div > div {
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 200px;
  display: grid;
  place-items: center;
}

#collab-hotel .grid-table {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  text-align: left;
  margin: 2rem 0;
}

#collab-hotel .grid-table div {
  margin-bottom: 3rem;
}

#collab-hotel .grid-table div h4,
#collab-company .grid-table div h4 {
  font-size: 1.8rem;
  text-align: center;
  min-height: 85px;
}

#collab-hotel .grid-table div p,
#collab-company .grid-table div p {
  margin: 1rem 0;
  text-align: justify;
}

#collab-hotel .grid-table div ul,
#collab-company .grid-table div ul {
  list-style-position: inside;
  text-align: left;
}

#collab-company {
  background: linear-gradient(45deg, #000, #242424);
}

#collab-company .grid-table {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  text-align: left;
  margin: 2rem 0;
}

#collab-company .grid-table div {
  margin-bottom: 3rem;
}

#collab-company .grid-table div h5 {
  font-size: 1.4rem;
  text-align: center;
  min-height: 85px;
}

#partners {
  background: #181818;
}

#partners .partners-grid {
  display: grid;
  grid-template: 70px / 1fr 1fr 1fr 1fr;
  gap: 2rem;
  place-items: center;
}

#partners .partners-grid img {
  max-height: 70px;
  max-width: 120px;
  transition: 0.3s all;
}

#partners .partners-grid img:hover {
  transform: scale(1.1);
}

#contact .grid-table {
  /* display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 2rem; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
#contact .grid-table > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  color: #fff;
}
#contact .grid-table > a > div {
  border: 4px solid;
  font-size: 3rem;
  width: 100px;
  height: 100px;
  display: grid;
  place-items: center;
  margin: 1rem 0 1rem 0;
  transition: 0.3s all;
}
#contact .grid-table > a > div:hover {
  border-radius: 2rem;
}

.parallax {
  height: 600px;
  background-image: url("/home/mock.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
.padding {
  padding: 2rem;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.width-600 {
  max-width: 600px;
  margin: 1rem auto;
}
.text-center {
  text-align: center;
}
iframe {
  min-height: 350px;
}

.ytp-chrome-top,
.ytp-chrome-bottom {
  display: none !important;
}
.nav-mobile-btn {
  display: none;
}

.btns-download-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  #collab-hotel .grid-table div h4,
  #collab-company .grid-table div h4,
  #collab-company .grid-table div h5 {
    min-height: auto;
  }
  #partners .partners-grid {
    display: grid;
    grid-template: 60px / 1fr 1fr;
    gap: 2rem;
    place-items: center;
  }

  #partners .partners-grid img {
    max-height: 60px;
    max-width: 100px;
  }
  .parallax {
    background-attachment: scroll;
  }
  nav,
  .login-btn {
    display: none;
  }
  .nav-mobile-btn {
    display: block;
    font-size: 32px;
  }
  .nav-mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: linear-gradient(45deg, #000, #242424);
    color: #fff;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }
  .nav-mobile ul {
    list-style-type: none;
  }
  .nav-mobile a {
    color: #fff;
  }
  .nav-mobile .nav-mobile-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 32px;
  }
  .nav-mobile .logo {
    height: 120px;
  }
  .pikto-grid {
    grid-template: repeat(6, 1fr) / 1fr;
  }
}
</style>
