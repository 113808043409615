<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
</template>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Oswald:wght@400;700&family=Roboto&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

html {
  scroll-behavior: smooth;
}

body {
  /* position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  /* -webkit-touch-callout: none; */
  background-color: #17398D;
  font-family: 'Caveat Brush', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  font-family: 'Oswald', sans-serif;
}

#app {
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #5BC236; /*#42b983;*/
}

.box {
  background: rgba(255,255,255,1);
  padding: 1rem;
  border-radius: .5rem;
  box-shadow: 0 0 20px 0 #000;
  position: relative;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 24px 0 24px;
  border-color: rgba(255,255,255,1) transparent transparent transparent;
  position: absolute;
  bottom: -47px;
  right: 25%;
}

.triangle-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 32px 18px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  bottom: 40px;
  left: -31px;
  z-index: 99999;
}

/* .bubble { 
	background-position: center;
  background-repeat: no-repeat !important;
	background-size: 100% 100%;
  margin: 0 auto;
	text-align: center;
	height: 0;
	box-sizing: content-box;
	line-height: 1;
}

.speech {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg); 
  width: 25%; 
  padding-top: 6%;
	padding-bottom: 20%;
  height: auto;
} */

button {
  background: #F0A824;
  color: #fff;
  border: none;
  padding: .5rem .8rem;
  border-radius: .25rem;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.5);
  margin: 1rem 0;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

button:hover {
  animation: pushButton .3s linear;
}

@keyframes pushButton {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes floatingButton {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
}

.white-text {
  color: #fff;
}

h1 {
  font-size: 2rem;
  margin-bottom: .5rem;
}

p {
  font-size: 1.2rem;
  line-height: 1.15rem;
}
.fa-power-off {
  cursor: pointer;
}
</style>
